@import "src/default";

.home-page-container {
    height: 100vh;
    width: 100%;

    .home-page-jumbotron {
        padding: 14em 0;
    }
}
