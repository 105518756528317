@import "src/default";

.main-layout-container {
  background-color: var(--background1);
  height: 100vh;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  //overflow: hidden;

  .main-layout-section {
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    padding: 20px 10px;
    //background-color: red;
    display: flex;
    flex-direction: column;

    .drawer-header {
      display: none;
      padding-left: 2.0rem;

      .icon {
        color: var(--text-primary);
        font-size: 3.0rem;
      }

      .drawer-close {
        margin-bottom: 2.0rem;
      }
    }
  }
}

// Drawer activate
@media only screen and (max-width: 960px){
  .main-layout-container {
    .main-layout-section {
      .drawer-header {
        display: flex;
      }
    }
  }
}
