@import "src/default";

.footer-container {
  //background-color: red;
  padding-top: 50px;
  border-top-style: solid;
  border-width: 2px;
  border-color: var(--background2);

  .footer-logo {
    margin-bottom: 25px;
    pointer-events: none;
  }
}
