
$blue: #1C42FF;
$blue_50: #e9e9ff;
$blue_100: #c8c9ff;
$blue_200: #a0a5ff;
$blue_300: #7481ff;
$blue_400: #4f62ff;
$blue_600: #1738f3;
$blue_700: #002be6;
$blue_800: #001bdb;
$blue_900: #0000cc;

$blueAnalogous_50: #e1f5ff;
$blueAnalogous_100: #b2e4ff;
$blueAnalogous_200: #7fd2ff;
$blueAnalogous_300: #49c1ff;
$blueAnalogous_400: #1cb3ff;
$blueAnalogous_500: #00a6fd;
$blueAnalogous_600: #0098ee;
$blueAnalogous_700: #0585d9;
$blueAnalogous_800: #0573c5;
$blueAnalogous_900: #1153a1;

$purple: #635984;
$purple_50: #e8e6ed;
$purple_100: #c6c2d3;
$purple_200: #a09bb6;
$purple_300: #7c7499;
$purple_500: #4b4071;
$purple_600: #453b6c;
$purple_700: #3c3463;
$purple_800: #342e58;
$purple_900: #27224b;

$purpleAnalogous_50: #eee6ff;
$purpleAnalogous_100: #d2c2fd;
$purpleAnalogous_200: #b39afd;
$purpleAnalogous_300: #916eff;
$purpleAnalogous_400: #714cfe;
$purpleAnalogous_500: #4a26fd;
$purpleAnalogous_600: #3722f6;
$purpleAnalogous_700: #021aee;
$purpleAnalogous_800: #0013e9;
$purpleAnalogous_900: #0000e4;

$red: #fe5678;
$red_50: #ffe3e8;
$red_100: #ffb8c5;
$red_200: #ff899e;
$red_400: #fa2a5b;
$red_500: #f20041;
$red_600: #e30040;
$red_700: #ce003e;
$red_800: #ba003e;
$red_900: #97003c;

$green: #00bba8;
$green_50: #dcf3f1;
$green_100: #a9e1da;
$green_200: #6ccfc1;
$green_400: #00ac95;
$green_500: #009b83;
$green_600: #008e76;
$green_700: #007e65;
$green_800: #006e57;
$green_900: #00513a;

$white: #f6f5fa;
$white_50: #fbfaff;
$white_200: #efeef3;
$white_300: #e2e1e6;
$white_400: #bfbec3;
$white_500: #a09fa4;
$white_600: #77767a;
$white_700: #636266;
$white_800: #444347;
$white_900: #222225;

$whiteAnalogous_50: #f9fbfe;
$whiteAnalogous_100: #f5f7fa;
$whiteAnalogous_200: #eff1f4;
$whiteAnalogous_300: #e2e3e7;
$whiteAnalogous_400: #bfc1c4;
$whiteAnalogous_500: #a1a2a5;
$whiteAnalogous_600: #78797c;
$whiteAnalogous_700: #636568;
$whiteAnalogous_800: #444548;
$whiteAnalogous_900: #232427;

// Radius
$radius_small: 1.0rem;
$radius_medium: 2.0rem;
$radius_large: 3.0rem;

:root {
  --background1: white;
  --background2: #{$whiteAnalogous_100};
  --text-primary: #{$purple_900};
  --selectedButton1: #{$purpleAnalogous_50};
  --hoverButton1: #{$whiteAnalogous_300};
}
[data-theme='"dark"'] {
  --background1: #{$whiteAnalogous_900};
  --background2: #{$whiteAnalogous_800};
  --text-primary: #{$whiteAnalogous_100};
  --selectedButton1: #{$purpleAnalogous_400};
  --hoverButton1: #{$whiteAnalogous_700};
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
}
html,
body {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  height: 100%;
}
*,
::before,
::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.App {
  // Body
  margin: 0 auto;
  padding: 0;

  // font
  font-family: 'Inter', sans-serif;
  h1 {
    line-height: 11.2rem;
    font-weight: 500;
    font-size: 11.2rem;
    letter-spacing: normal;
    color: var(--text-primary);
    text-align: center;
    margin: 0 0 .8rem;
  }
  h2 {
    line-height: 6.4rem;
    font-weight: 500;
    font-size: 5.7rem;
    letter-spacing: normal;
    color: var(--text-primary);
    text-align: start;
    margin: 0 0 .8rem;
  }
  h3 {
    line-height: 44px;
    font-weight: 400;
    font-size: 36px;
    letter-spacing: 0;
    color: var(--text-primary);
  }
  //h4 {
  //  font-weight: 400;
  //  font-size: 34px;
  //  letter-spacing: 0.25px;
  //}
  //h5 {
  //  font-weight: 400;
  //  font-size: 24px;
  //  letter-spacing: 0;
  //}
  //h6 {
  //  font-weight: 500;
  //  font-size: 20px;
  //  letter-spacing: 0.15px;
  //}
  a {
    text-decoration: none;
    color: var(--text-primary);
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: start;
    letter-spacing: normal;
    margin: 0;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: start;
    letter-spacing: normal;
    margin: 0;
    color: var(--text-primary);
  }
  .subtitle1 {
    line-height: 4.0rem;
    font-weight: 400;
    font-size: 3.2rem;
    letter-spacing: 0;
    color: var(--text-primary);
  }
  .subtitle2 {
    line-height: 36px;
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0;
    color: var(--text-primary);
  }
  .subtitle3 {
    line-height: 32px;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0;
    color: var(--text-primary);
  }
  .title1 {
    line-height: 3.2rem;
    font-weight: 500;
    font-size: 2.4rem;
    letter-spacing: normal;
    color: var(--text-primary);
    text-align: start;
  }
  .title2 {
    line-height: 2.4rem;
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0.15px;
    color: var(--text-primary);
  }
  .title3 {
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: var(--text-primary);
  }
  .label1 {
    line-height: 1.6rem;
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 0.01rem;
    color: var(--text-primary);
    text-align: center;
  }
  .label2 {
    line-height: 16px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: var(--text-primary);
  }
  .label3 {
    line-height: 16px;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: var(--text-primary);
  }
  .body1 {
    line-height: 2.4rem;
    font-weight: 400;
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    color: var(--text-primary);
  }
  .body2 {
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--text-primary);
  }
  .body3 {
    line-height: 16px;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--text-primary);
  }
  .button {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.15px;
    text-transform: uppercase;
    color: var(--text-primary);
  }
  .caption {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: var(--text-primary);
  }
  .overline {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--text-primary);
  }
  .description {
    line-height: 3.0rem;
    font-weight: 400;
    font-size: 2.2rem;
    letter-spacing: normal;
    color: var(--text-primary);
    text-align: center;
  }
  //h1 {
  //  font-weight: 300;
  //  font-size: 96px;
  //  letter-spacing: -1.5px;
  //}
  //h2 {
  //  font-weight: 300;
  //  font-size: 60px;
  //  letter-spacing: -0.5px;
  //}
  //h3 {
  //  font-weight: 400;
  //  font-size: 48px;
  //  letter-spacing: 0;
  //}
  //h4 {
  //  font-weight: 400;
  //  font-size: 34px;
  //  letter-spacing: 0.25px;
  //}
  //h5 {
  //  font-weight: 400;
  //  font-size: 24px;
  //  letter-spacing: 0;
  //}
  //h6 {
  //  font-weight: 500;
  //  font-size: 20px;
  //  letter-spacing: 0.15px;
  //}
  //.subtitle1 {
  //  font-weight: 400;
  //  font-size: 16px;
  //  letter-spacing: 0.15px;
  //}
  //.subtitle2 {
  //  font-weight: 500;
  //  font-size: 14px;
  //  letter-spacing: 0.1px;
  //}
  //.body1 {
  //  font-weight: 400;
  //  font-size: 16px;
  //  letter-spacing: 0.5px;
  //}
  //.body2 {
  //  font-weight: 400;
  //  font-size: 14px;
  //  letter-spacing: 0.25px;
  //}
  //.button {
  //  font-weight: 500;
  //  font-size: 14px;
  //  letter-spacing: 1.15px;
  //  text-transform: uppercase;
  //}
  //.caption {
  //  font-weight: 400;
  //  font-size: 12px;
  //  letter-spacing: 0.4px;
  //}
  //.overline {
  //  font-weight: 400;
  //  font-size: 10px;
  //  letter-spacing: 1.5px;
  //  text-transform: uppercase;
  //}

  // container
  .rounded1 {
    padding: 40px;
    border-radius: $radius_large;
  }
  .box1 {
    margin: 100px 50px 0;
    //width: 100%;
    height: fit-content;
  }
  .toggle {
    display: flex;
    flex-direction: row;
    width: fit-content;
    padding: 1.1rem;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid var(--text-primary);
    border-radius: $radius_large;
  }
}

// Mobile devices
@media only screen and (max-width: 599px) {
  /* CSS styles for mobile devices */
  .App {
    // font
    h1 {
      line-height: 6.4rem;
      font-size: 5.7rem;
    }

    h2 {
      line-height: 4.4rem;
      font-size: 3.6rem;
    }
    a {
      font-size: 1.4rem;
      line-height: 2.0rem;
    }
    p {
      font-size: 1.4rem;
      line-height: 2.0rem;
    }
    .title1 {
    }
    .description {
    }
    .label1 {
    }

    // container
    .rounded {
      padding: 30px;
      border-radius: 30px
    }
  }
}

// Sidebar
@media only screen and (max-width: 960px){
  .App {
    .label1 {
      line-height: 2.4rem;
      font-size: 1.6rem;
    }
  }
}

// Tablets
@media only screen and (min-width: 600px) and (max-width: 1300px) {
  // CSS styles for tablets
  .App {
    // Font
    h1 {
      line-height: 9.6rem;
      font-size: 8.8rem;
    }

    h2 {
      line-height: 5.2rem;
      font-size: 4.5rem;
    }
    .title1 {
    }
    .description {
      line-height: 2.0rem;
      font-size: 1.4rem;
    }
    .label1 {
    }
  }
}

// Small laptops and desktops
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  // CSS styles for small laptops and desktops
}

// Large laptops and desktops
@media only screen and (min-width: 1200px) {
  // CSS styles for large laptops and desktops
}
