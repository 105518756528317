@import "src/default";

.sidebar-container {
  height: 100vh;
  width: fit-content;
  background-color: var(--background2);
  padding: 20px 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .sidebar-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .sidebar-button {
    //background-color: red;
    padding: 0 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .icon-container {
      //background-color: orange;
      width: 60px;
      height: 38px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon-selected {
      background-color: var(--selectedButton1);
    }
    .icon {
        color: var(--text-primary);
        font-size: 24px;
    }
    .homeLogoButton {
      //height: 24px;
      pointer-events: none;
    }
    .theme {
      width: fit-content;
      height: fit-content;
      padding: 15px;
      border-radius: 50px;
      border: 1px solid var(--text-primary);
    }

    p {
      //background-color: blue;
    }
  }

  // Hovered icons
  .sidebar-button:hover {
    cursor: pointer;

    .icon-container {
      background-color: var(--hoverButton1);
    }
  }
}

// Drawer toggle
.drawer-open {
  //background-color: red;
}

// Drawer
.drawer {
  height: 100vh;
  width: 32.0rem;
  position: absolute;
  padding: 2.0rem 1.5rem;
  background-color: var(--background2);
  border-bottom-right-radius: $radius_medium;
  border-top-right-radius: 2.0rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;

  .icon {
    color: var(--text-primary);
    font-size: 3.0rem;
  }

  .drawer-close {
    margin-bottom: 2.0rem;
  }

  .drawer-container {
    display: flex;
    flex-direction: column;
    //background-color: blue;

    .drawer-close {
      padding: 0 1.5rem;
    }
  }

  .drawer-button {
    //background-color: red;
    padding: 1.1rem 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 2.0rem;
    border-radius: $radius_large;
  }

  .icon-selected {
    background-color: var(--selectedButton1);
  }

  .drawer-theme {
    gap: 1.0rem;
    margin: 0 auto;
  }
}

.openDrawer {
  display: flex;
}

// Drawer activate
@media only screen and (max-width: 960px){
  .sidebar-container {
    display: none;
  }
}
