@import "src/default";

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    height: 150px;
    pointer-events: none;
  }

  .info {
    background-color: var(--background2);
    margin-top: 30px
  }
}

// Mobile devices
@media only screen and (max-width: 767px) {
  // CSS styles for mobile devices
  .container {
    .logo {
      height: auto;
      width: 90%;
    }
  }
}

//Tablets
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  // CSS styles for tablets
}

// Small laptops and desktops
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  // CSS styles for small laptops and desktops
}

// Large laptops and desktops
@media only screen and (min-width: 1200px) {
  // CSS styles for large laptops and desktops
}
